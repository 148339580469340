import * as Popover from "@radix-ui/react-popover";
import React from "react";
import { t } from "ttag";

import { Clickable } from "@thelabnyc/thelabui/src/components/Clickable";

import { Svg } from "../../../components/Svg";
import { Location } from "../../../utils/models";

import styles from "./PopoverTrigger.module.scss";

const getIndicatorTitle = (location: Location | null): string => {
    if (!location) {
        return t`Find A Location Near You`;
    }
    if (location.isTemporarilyClosed) {
        return t`${location.city}, ${location.state} (Restaurant Temporarily Closed)`;
    }
    if (location.isDiningRoomOpened && location.outsideDining) {
        return t`${location.city}, ${location.state} (Indoor/Outdoor Dining Available)`;
    }
    if (location.isDiningRoomOpened && !location.outsideDining) {
        return t`${location.city}, ${location.state} (Indoor Dining Available)`;
    }
    if (!location.isDiningRoomOpened && location.outsideDining) {
        return t`${location.city}, ${location.state} (Outdoor Dining Available)`;
    }
    if (location.hasDelivery) {
        return t`${location.city}, ${location.state} (Delivery Available)`;
    }
    if (location.hasCurbside) {
        return t`${location.city}, ${location.state} (Curbside Available)`;
    }
    return t`${location.city}, ${location.state} (Pick Up Only)`;
};

export const PopoverTrigger = (props: { location: Location | null }) => (
    <Popover.Trigger asChild>
        <Clickable className={styles.trigger}>
            <span className={styles.pinIcon}>
                <Svg name="pin" />
            </span>
            {getIndicatorTitle(props.location)}
            <span className={styles.caretIcon}>
                <Svg name="caret-down" />
            </span>
        </Clickable>
    </Popover.Trigger>
);
