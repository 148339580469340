import React from "react";

import { HeroBlock as Value } from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import styles from "./index.module.scss";

interface Props {
    value: Value;
}

interface IHero {
    imageURL: string;
    imageAlignment?: string;
    textStyle: string;
    title: string;
    subtitle?: string;
    link_text?: string;
    link?: string | null;
}

export const Hero = (props: IHero) => {
    const getStyleClass = (style: string | null) => {
        switch (style) {
            case "dark": {
                return styles.textDark;
            }
            default:
                return styles.textLight;
        }
    };
    const getImageAlignment = (style: string | null) => {
        switch (style) {
            case "align-left": {
                return "left";
            }
            case "align-center": {
                return "center";
            }
            case "align-right": {
                return "right";
            }
            default:
                return undefined;
        }
    };
    const imageAlignment = getImageAlignment(
        props.imageAlignment || "align-center",
    );

    return (
        <section
            className={concatClassNames([
                styles.container,
                getStyleClass(props.textStyle),
            ])}
            style={
                {
                    "--background-image": `url(${props.imageURL || ""})`,
                    "--background-position": `${imageAlignment || ""}`,
                } as React.CSSProperties
            }
        >
            <h1 className={styles.title}>{props.title}</h1>
            {props.subtitle && (
                <span className={styles.subtitle}>{props.subtitle}</span>
            )}
            {props.link && (
                <a
                    href={props.link}
                    className={concatClassNames(["button", styles.link])}
                >
                    {props.link_text}
                </a>
            )}
        </section>
    );
};

export const HeroBlock = (props: Props) => {
    return (
        <Hero
            imageURL={props.value.image?.url || ""}
            imageAlignment={props.value.image_alignment || ""}
            textStyle={props.value.text_style || ""}
            title={props.value.title || ""}
            subtitle={props.value.subtitle || ""}
            link_text={props.value.link_text || ""}
            link={props.value.link?.url || null}
        />
    );
};
