import React from "react";

import { ImageChooserBlockList as Value } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";

import styles from "./index.module.scss";

interface Props {
    value: Value;
}

export const SocialGridBlock = (props: Props) => (
    <div className={styles.socialGridBlockContainer}>
        <p className={styles.intro}>
            Tag your memories on Instagram with <strong>#RedLobster</strong>
        </p>
        <div className={styles.images}>
            {props.value.map((block, i) => (
                <React.Fragment key={i}>
                    {block && <ImageChooserBlock value={block} />}
                </React.Fragment>
            ))}
        </div>
    </div>
);
